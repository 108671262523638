<template>
  <el-container>
    <el-main>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-tabs v-model="pageTabs">
          <el-tab-pane label="基本信息" name="first">
            <el-form-item label="商户名称：" prop="storename">
              <el-input v-model="form.storename" class="nomalwidth"></el-input>
            </el-form-item>
            <el-form-item label="商户类型：" prop="store_type">
              <el-select v-model="form.store_type" filterable>
                <el-option v-for="item in store_type_list" :key="item.type" :label="item.name" :value="item.type"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商户简介：" prop="storename">
              <el-input v-model="form.store_intro" class="nomalwidth"></el-input>
            </el-form-item>
            <el-form-item label="商户logo：" prop="storelogo">
              <ReadyUploadSource
                :showStyle="{
                  width: '160px',
                  height: '160px',
                }"
                @getSource="getLogoImg"
                :path="form.storelogo"
                @removeThis="() => (form.storelogo = '')"
              ></ReadyUploadSource>
              <div class="formdiv">建议图片尺寸216*216，大小不超过200KB</div>
            </el-form-item>

            <el-form-item label="商户分类：" prop="store_classify_id">
              <el-select value-key="id" v-model="form.store_classify_id" clearable style="width: 100%" placeholder="请选择">
                <el-option v-for="item in typeList" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商户banner：" prop="storebanner">
              <ReadyUploadSource
                :showStyle="{
                  width: '215px',
                  height: '90px',
                }"
                :isMany="true"
                @changeOrder="changeBanner"
                @removeThis="removeBannerThis"
                @getSource="getBannerImg"
                :manyPath="form.storebanner"
              ></ReadyUploadSource>
              <div class="formdiv">建议图片尺寸530px*180px，大小不超过200KB</div>
            </el-form-item>
            <el-form-item label="商户电话：" prop="storetel">
              <el-input v-model="form.storetel" class="nomalwidth"></el-input>
            </el-form-item>
            <el-form-item label="所属区域：" prop="pass">
              <el-cascader v-model="areaArr" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
            </el-form-item>
            <el-form-item label="商户位置：" required>
              <div style="display: flex">
                <el-form-item prop="storelongitude" style="margin-right: 50px">
                  <el-input disabled v-model="form.storelongitude" placeholder="地理经度" class="halfwidth"></el-input>
                </el-form-item>
                <el-form-item prop="storelatitude" style="margin-right: 40px">
                  <el-input disabled v-model="form.storelatitude" placeholder="地理纬度" class="halfwidth"></el-input>
                </el-form-item>
                <!-- <el-link type="primary" :underline="false" style="white-space: nowrap">选择坐标</el-link> -->
                <el-button type="primary" @click="dialogMap = true">定位</el-button>
                <el-dialog title="选择坐标" :visible.sync="dialogMap">
                  <Map ref="map" :lng="form.storelongitude" :lat="form.storelatitude"></Map>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogMap = false">取 消</el-button>
                    <el-button type="primary" @click="confirmCoordinate">确 定</el-button>
                  </div>
                </el-dialog>
              </div>
            </el-form-item>
            <el-form-item label="商户详细地址：" prop="storeaddress">
              <el-input disabled v-model="form.storeaddress" class="nomalwidth"></el-input>
            </el-form-item>
            <el-form-item label="营业时间：" required>
              <div style="display: flex; margin-bottom: 20px" v-for="(item, index) in form.dotime" :key="index">
                <el-form-item style="margin-right: 50px" :error="item.isShowStartError ? '请输入开始时间' : false" :show-message="item.isShowStartError">
                  <el-time-picker v-model="item.dotimestart" placeholder="开始时间" class="halfwidth" @change="validateDotimestart(item)" value-format="HH:mm" format="HH:mm"></el-time-picker>
                </el-form-item>
                <el-form-item style="margin-right: 50px" :error="item.isShowEndError ? '请输入结束时间' : false" :show-message="item.isShowEndError">
                  <el-time-picker v-model="item.dotimeend" placeholder="结束时间" class="halfwidth" @change="validateDotimeend(item)" value-format="HH:mm" format="HH:mm"></el-time-picker>
                  <i v-if="index != 0" @click="deleteDoTime(index)" style="margin-left: 20px; cursor: pointer" class="el-icon-close"></i>
                </el-form-item>
              </div>
              <div>
                <el-link type="primary" :underline="false" icon="el-icon-plus" @click="addDotime" style="line-height: 20px" v-if="form.dotime.length < 3">添加营业时间</el-link>
              </div>
            </el-form-item>
            <!-- 添加商户label -->
            <el-form-item label="商户标签：">
              <div class="labelBox">
                <el-input style="width: 150px; margin-right: 14px" v-model="item.name" v-for="(item, index) in storeLabel" :key="index"></el-input>
                <el-link type="primary" :underline="false" icon="el-icon-plus" @click="addStoreLabel" style="line-height: 20px" v-if="form.dotime.length < 3">添加标签</el-link>
              </div>
            </el-form-item>
            <el-form-item label="是否支持自提：">
              <el-switch v-model="form.isselftake"></el-switch>
            </el-form-item>
            <el-form-item label="是否营业：">
              <el-switch v-model="form.isdo"></el-switch>
            </el-form-item>
            <el-form-item label="利润抽成：">
              <el-input :maxlength="3" placeholder="此项只能填写数字" v-model="form.profit" class="nomalwidth"></el-input>
              <span style="color: red; margin-left: 10px" v-if="isNaN(Number(form.profit))">输入的数据不正确</span>
              <span style="color: red; margin-left: 10px" class="error" v-else-if="Number(form.profit) < 0 || Number(form.profit) > 100">费率的区间在[0-100]</span>
              <span style="color: #409eff; margin-left: 10px" class="tips" v-else>商户扣费率1000元抽成{{ Math.ceil(((Number(form.profit) / 100) * 1000).toFixed(2)) }}元</span>
            </el-form-item>
            <!-- <el-form-item>
          <el-button size="small" type="primary" style="width: 80px" @click="saveForm">保存</el-button>
        </el-form-item> -->
          </el-tab-pane>
          <el-tab-pane label="规则须知" name="second">
            <RichText v-if="pageTabs == 'second'" width="600" height="400" editorId="regular_node" :richTxt="form.regular_node" @getContent="getContent"></RichText>
          </el-tab-pane>
          <el-tab-pane label="场地特色" name="third">
            <RichText v-if="pageTabs == 'third'" width="600" height="400" editorId="site_character" :richTxt="form.site_character" @getContent="getContent"></RichText>
          </el-tab-pane>
        </el-tabs>

        <Preservation @preservation="saveForm">
          <router-link to="/store/storeList" slot="return" v-if="form.id" style="margin-right: 10px">
            <el-button>返回</el-button>
          </router-link>
        </Preservation>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import Map from './components/Map';
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
export default {
  components: {
    Map,
    ReadyUploadSource,
    Preservation,
    RichText,
  },
  data: function () {
    return {
      pageTabs: 'first',
      classify_name: '',
      form: {
        storename: null,
        storelogo: null,
        storebanner: [],
        storetel: null,
        store_intro: null,
        storeaddress: null,
        storelongitude: null,
        storelatitude: null,
        store_classify_id: '',
        store_type: 1,
        dotime: [
          {
            dotimestart: null,
            dotimeend: null,
            isShowStartError: false,
            isShowEndError: false,
          },
        ],
        isselftake: false,
        isdo: false,
        profit: 0.6,
        site_character: '',
        regular_node: '',
      },
      rules: {
        storename: [{ required: true, message: '请输入商户名称', trigger: 'blur' }],
        storelogo: [{ required: true, message: '请输入商户logo', trigger: 'blur' }],
        storebanner: [{ required: true, message: '请输入商户banner', trigger: 'blur' }],
        storetel: [{ required: true, message: '请输入商户电话', trigger: 'blur' }],
        storeaddress: [{ required: true, message: '请输入商户地址', trigger: 'blur' }],
        storelongitude: [{ required: true, message: '请输入地理经度', trigger: 'blur' }],
        storelatitude: [{ required: true, message: '请输入地理纬度', trigger: 'blur' }],
        store_classify_id: [{ required: true, message: '请选择商户分类', trigger: 'blur' }],
      },
      dialogMap: false,
      store_type_list: [
        { type: 1, name: '数字门店' },
        { type: 2, name: '线下门店' },
        { type: 3, name: '供应链' },
      ],
      storeLabel: [{ name: '' }],
      areaArr: [],
      areaList: [],
    };
  },
  created() {
    this.getArea();
    this.getType();
    if (this.$route.query.info) {
      let info = JSON.parse(this.$route.query.info);
      this.$axios
        .post(this.$api.store.storeList, {
          store_name: info.store_name,
        })
        .then(res => {
          if (res.code == 0) {
            let obj = res.result.list.filter(item => item.id === info.id)[0];
            let dotime = [];
            for (let v of JSON.parse(obj.open_time)) {
              dotime.push({
                dotimestart: v.start_time,
                dotimeend: v.end_time,
                isShowStartError: false,
                isShowEndError: false,
              });
            }
            this.logoPath = obj.store_logo;
            let label = [];
            if (obj.label) {
              obj.label.split(',').map(i => {
                label.push({ name: i });
              });
            }
            this.areaArr = [Number(obj.province_id), Number(obj.city_id), Number(obj.area_id)];
            this.storeLabel = label;
            this.form = {
              id: obj.id,
              storename: obj.store_name,
              storelogo: obj.store_logo,
              store_classify_id: Number(obj.store_classify_id),
              storebanner: obj.store_banners,
              store_intro: obj.store_intro,
              storetel: obj.store_phone,
              storeaddress: obj.store_site,
              storelongitude: obj.store_longitude,
              storelatitude: obj.store_latitude,
              dotime: dotime,
              isselftake: obj.is_take_their === 1,
              isdo: obj.is_open === 1,
              profit: obj.rake,
              regular_node: obj.regular_node,
              site_character: obj.site_character,
              store_type: obj.store_type,
            };
          } else {
            this.$message.error(res.msg);
          }
        });
    } else {
      return false;
    }
  },
  methods: {
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    addStoreLabel() {
      if (this.storeLabel.length == 2) {
        this.$message.warning('最多只能添加两个标签');
        return;
      }
      this.storeLabel.push({ name: '' });
    },
    getContent(e, type) {
      if (type == 'site_character') {
        this.form.site_character = e;
      } else {
        this.form.regular_node = e;
      }
    },
    deleteDoTime(index) {
      this.form.dotime.splice(index, 1);
    },
    getLogoImg(val) {
      this.form.storelogo = val.path;
    },
    // 获取选中的banner
    getBannerImg(val) {
      val.map(item => {
        this.form.storebanner.push(item.path);
      });
    },
    // 改变banner顺序
    changeBanner(list) {
      list.map((item, index) => {
        this.form.storebanner[index] = item;
      });
    },
    // 删除某个banner
    removeBannerThis(index) {
      this.form.storebanner.splice(index, 1);
    },
    saveForm() {
      this.$refs['form'].validate(valid => {
        let flag = false;
        for (let i of this.form.dotime) {
          if (!i.dotimestart) {
            flag = true;
            i.isShowStartError = true;
          }
          if (!i.dotimeend) {
            flag = true;
            i.isShowEndError = true;
          }
        }
        if (valid && !flag) {
          let form = this.form;
          let open_time = [];
          for (let v of form.dotime) {
            open_time.push({
              start_time: v.dotimestart,
              end_time: v.dotimeend,
            });
          }
          if (isNaN(Number(form.profit))) {
            this.$message({
              message: '利润抽成只能填写数值',
              type: 'warning',
            });
            return;
          } else {
            if (Number(form.profit) < 0 || Number(form.profit) > 100) {
              this.$message({
                message: '利润抽成区间在[0,100]之间',
                type: 'warning',
              });
              return;
            }
          }
          if (!this.form.regular_node) {
            this.$message.warning('请填写店铺规则须知');
            this.pageTabs = 'second';
            return;
          }
          if (!this.form.site_character) {
            this.$message.warning('请填写店铺场地特色');
            this.pageTabs = 'third';
            return;
          }
          if (!this.areaArr || this.areaArr.length != 3) {
            this.$message.warning('请选择所选区域');
            this.pageTabs = 'first';
            return;
          }
          let data = {
            store_name: form.storename,
            store_logo: form.storelogo,
            classify_id: form.store_classify_id,
            store_banner: form.storebanner,
            store_intro: form.store_intro,
            store_phone: form.storetel,
            store_site: form.storeaddress,
            store_type: form.store_type,
            store_longitude: form.storelongitude,
            store_latitude: form.storelatitude,
            is_take_their: form.isselftake ? 1 : 0,
            is_open: form.isdo ? 1 : 0,
            rake: form.profit,
            open_time: open_time,
            site_character: form.site_character,
            regular_node: form.regular_node,
            province_id: this.areaArr[0],
            city_id: this.areaArr[1],
            area_id: this.areaArr[2],
          };
          if (form.id) data.id = form.id;
          let lable = '';
          for (let i = 0; i < this.storeLabel.length; i++) {
            if (!this.storeLabel[i].name) continue;
            lable += i == 0 ? this.storeLabel[i].name : ',' + this.storeLabel[i].name;
          }
          if (lable) data.label = lable;
          this.$axios.post(form.id ? this.$api.store.editStore : this.$api.store.addStore, data).then(res => {
            if (res.code == 0) {
              this.$message.success(form.id ? '编辑商户成功！' : '添加商户成功！');
              this.$router.push({ path: '/store/storeList' });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    addDotime() {
      if (this.form.dotime.length < 3) {
        this.form.dotime.push({
          dotimestart: null,
          dotimeend: null,
          isShowStartError: false,
          isShowEndError: false,
        });
      }
    },
    getType() {
      this.$axios
        .post(this.$api.store.typeList, {
          store_classify_id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.typeList = res.result.list;
          }
        });
    },
    validateDotimestart(item) {
      item.isShowStartError = !item.dotimestart;
    },
    validateDotimeend(item) {
      item.isShowEndError = !item.dotimeend;
    },
    confirmCoordinate() {
      let coordinate = this.$refs.map.currentCoordinate;
      let obj = this.$refs.map.address;
      if (!obj) {
        this.$message.warning('请输入定位地址');
        return;
      }
      this.form.storeaddress = obj.province + obj.city + obj.district + obj.street + obj.streetNumber + this.$refs.map.search;
      this.form.storelongitude = coordinate.lng;
      this.form.storelatitude = coordinate.lat;
      this.dialogMap = false;
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  background: #fff;
  height: 100%;
}

.el-main {
  padding: 40px 60px 60px 40px;
  background: #fff;
  height: 100%;
}

.formlogo {
  width: 120px;
  height: 120px;
  border: 1px solid #dcdfe6;
}

.logodiv {
  width: 120px;
  height: 120px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    font-size: 14px;
    color: #9a9a9a;
    margin-left: 3px;
  }
}

.bannerdiv {
  width: 530px;
  height: 180px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    font-size: 14px;
    color: #9a9a9a;
    margin-left: 3px;
  }
}

.formdiv {
  color: #9a9a9a;
}

.nomalwidth {
  width: 780px;
}

.halfwidth {
  width: 365px;
}

/deep/ .el-dialog__wrapper {
  display: flex;
  align-items: center;

  .el-dialog {
    margin: 0 auto !important;
  }
}

.bannerList {
  width: 700px;
  display: flex;
  flex-wrap: wrap;

  .listItem {
    margin: 0px 10px 10px 0px;
    position: relative;

    i {
      color: #909399;
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }
  }

  .imgBox {
    width: 200px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    overflow: hidden;
    border: 1px solid #c0ccda;
  }
}

.addImg {
  width: 200px;
  height: 80px;
  border: 1px dashed #c0ccda;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 28px;
    color: #8c939d;
  }
}
</style>
